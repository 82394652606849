/* Home Screen Mobile CSS Starts */

.menuPanelLeftMble, .lSideBar, .rSideDot, .loginPanelMble{
	display: none;
}
.hideFromDesk {
	display: none;
}
.hideBtnFromMble {
	display: inline-block;
}
.hideBtnFromDesk {
	display: none;
}

@media screen and (max-width: 780px) and (min-width: 320px) {
	// .displayBlockMble {
	// 	display: block !important;
	// }
	// .displayNoneMble {
	// 	display: none !important;
	// }
	.container-fluid.routerOutlet{
		margin-top: 40px;
	}
	.hideFromDesk {
		display: block;
	}
	.hideBtnFromDesk {
		display: inline-block;
	}
	.hideBtnFromMble {
		display: none;
	}
	body{
		min-width: auto;
		padding: 0px;
	}
	.headerLogo.headerPanelMble {
		width: 120px;
	}
	.lSideBar{
		display: inline-block;
		position: absolute;
		z-index: 9;
		color: #fff;
		font-size: 24px;
		padding: 5px 10px 5px 5px;
		float: left;	
		cursor: pointer;																		
	}
	.mbleLogoPanel{
		display: inline-block;
		margin-top: -6px;
		margin-left: 35px;
		height: 50px;
	}
	.headerPanel.headerPanelMble .headerAlwaysLearning {
		margin-right: 5px;
	}
	.headerPanel.headerPanelMble .rSideDot{
		display: block;
		float: right;
		color: #fff;
		font-size: 24px;
		padding: 8px;
		
	}
	.menuPanel{
		display: none;
	}
	.menuPanelLeftMble{
		display: block;
		z-index: 999;
		position: fixed;
		height: 93%;
		background: #2150a3;
		top: 44px;
		border: none;
		left: 0px;
	}
	.menuPanelLeftMble a.menuPanelItem:first-child {
		margin-left: 5px;
		margin-top: 20px;
	}
	.menuPanelLeftMble a.menuPanelItem {
		display: block;
		font-size: 16px;
		background-color: #2150a3;
		color: #fff;
	}
	.menuPanelLeftMble a.menuPanelItem.active span{
		border-bottom: 2px solid #fff;
	}
	.menuPanelLeftMble a.menuPanelItem span {
		padding-bottom: 5px;
	}
	.countrySelect{
		font-size: 18px !important;
		margin: 0 !important;
	}
	.curriculumCountryList {
		margin-left: 0px !important;
		font-size: 12px !important;
	}
	.searchResultNotInitiatedTitle{
		font-size: 12px !important;        
	}
	.searchResultNotInitiatedPanel .searchResultNotInitiatedText{
		font-size: 12px !important;
	}
	.searchResultNotInitiatedPanel{
		margin-left: 0 !important;
		margin-top: 0 !important;
		background-image: none !important;
		padding: 0 !important;
	}
	.view-country-list-conatiner.row{
	    padding: 0 2%;
	    width: 100%;
		margin-top: 40px !important;
	}
	.col-sm-10.viewSearchResultPanel{
		width: 70%;
		margin-top: 25px !important;
		margin-left: 0;
		float: left;
		padding: 15px !important;
	}
	.view-country-list-conatiner.row .col-sm-2{
		width: 30%;
		margin: 0;
		float: left;
	}
	.loginPanelMble{
		display: block;
		z-index: 999;
	}
	.loginPanelMble a.menuPanelItem {
		background-color: #2150a3;
		color: #fff;
	}
	.loginPanelMble .navRightMenu{
		background: #2150a3;
		position: fixed;
		z-index: 999;
		right: -22px;
		text-align: right;
		top: 75px;
		width: max-content;
	}
	div#AreYouSurePopup {
		width: 94% !important;
		margin: 0 !important;
	}
	
}
/* Home Screen Mobile CSS Ends */

/* Manage Screen Mobile CSS Starts */

.curriculamFilterMobView {
	display: none;
}
@media screen and (max-width: 780px) and (min-width: 320px) {
	.webViewManageList, .curriculamFilter.clearfix{
		display: none;
	}
	.navPillMbleMng {
		width: max-content;
	}
	.curriculumMenu.curriculumMenuPanel::-webkit-scrollbar {
		display: none;
	}
	.curriculamFilterMobView {
		display: block;
		background: #fff;
		position: fixed;
		z-index: 9;
		top: 80px;
		padding-top: 20px;
		width: 100%;
	}
	.curriculumTab {
		margin-top: 150px;
	}
	.searchContentCurrFilt{
		width: 50%;
		float: left;
	}
	.curricFilterSelect {
		width: 85% !important;
	}
	.curricFilterSelect.currSearchKey {
		width: 95% !important;
	}
	.curriculamFilterMobView .moreFilterOption{
		width: 20%;
		font-size: 24px;
		float: left;
		text-align: center;
	}
	.curriculamFilterMobView .moreFilterOption .fa-filter{ 
		padding: 5px 10px;
		box-shadow: 0px 0px 4px 0px #7b7b7b
	}
	.moreMangeOption {
		display: inline-block;
		width: 30%;
		float: left;
	}
	.moreMangeOptionBtn {
		display: inline-block;
		padding: 5px 10px;
		background: #2150a3;
		cursor: pointer;
		color: #fff;
		border: 1px solid #2150a3;
	}
	.curriculumTotal{
		margin: 0px;
		padding-top: 15px;
	}
	.curriculumHighlightList.ng-scope {
		width: 100%;
		overflow-x: hidden;
	}
	.curriculumCountry {
		margin-left: 5px;
	}
	.curriculumBackground {
		margin: 0;
	}
	table.curriculumTable {
		width: 100%;
		margin-bottom: 12px;
		position: relative;
	}
	table.curriculumTable tbody {
		display: block;
	}
	table.curriculumTable tr{
		margin-top: 10px;
		display: block;
	}
	table.curriculumTable tbody td.curriculumButtonPanel{
		width: 100%;
		display: block;
		height: auto;
		margin-top: 0;
		font-size: 16px;
	}

	table.curriculumTable tbody td{
		display: block;
		width: 90%;
		clear: both;
		padding: 5px 5px;
	}
	table.curriculumTable td.curriculumDeleteIcon {
		display: block;
		width: 6%;
		position: absolute;
		right: 0;
		left: 93%;
		top: 50%;
	}
	table.curriculumTable td .curriculumInfoPanel {
		padding-right: 0px;
	}
	.curriculumButtonPanel input.cmButton.cmButton-green.cmButton-large.marginLeft24px {
		display: inline-block;
		float: left;
		margin-left: 0;
		font-size: 85%;
		width: auto !important;
	}
	.curriculumButtonPanel input, .curriculumButtonPanel span{
		display: inline-block;
		float: left;
	}
	.awaitingApprTab .curriculumButtonPanel input, .awaitingApprTab .curriculumButtonPanel span {
		font-size: 85%;
		width: auto !important;
		margin-left: 10px;
	}
	.awaitingApprTab .curriculumButtonPanel input:first-child, .awaitingApprTab img.substitutionIcon{
		margin-left: 0px;
	}
	.approvedTab table.curriculumTable tbody td.mbleButtonApprovedTab {
		display: inline-block;
		width: auto;
		margin-top: 10px;
	}
	.approvedTab table.curriculumTable tbody td.mbleButtonApprovedTab.curriculumButtonPanel {
		margin-top: 0px;
	}
	.approvedTab td.mbleButtonApprovedTab .cmButton-large.marginLeft24px {
		width: auto !important;
		font-size: 85%;
		margin-left: 0px;
	}
	.approveTabCurrTable {
		margin-left: 0px;
		margin-right: 0px;
	}
	.approvedTab table.curriculumTable tbody td, .rejectedTab table.curriculumTable tbody td, .deletedTab table.curriculumTable tbody td{
		width: 100%;
	}
	.approvedTab table.curriculumTable tbody td .curriculumInfoPanel{
		margin-top: 0;
		padding-top: 15px;
	}
	.mblermngFilter {
		width: 100%;
		position: fixed;
		background: #fff;
		top: 137px;
		left: 0;
		background-color: #fff;
		padding: 5%;
		transition: opacity 0.3s linear;
	}
	.mbleCurrProdMngMenu {
		position: fixed;
		background: #fff;
		width: auto;
		right: 0px;
		padding: 10px;
		top: 140px;
		max-height: 50%;
		overflow-y: scroll;
		transition: opacity 0.3s linear;
	}
	.mbleMoreMenu a {
		padding-left: 5px;
		font-size: 14px;
		display: block;
	}
	.mbleMoreMenu label {
		color: #666666;
		font-size: 14px;
	}
}
/* Manage Screen Mobile CSS Ends */

/* View curriculum by country Screen Mobile CSS Starts */
@media screen and (max-width: 780px) and (min-width: 320px) {
	.curriculumTab.viewListByCount {
		margin-top: 75px !important;
	}
	.viewListByCount .curriculamFilter.clearfix {
		display: block;
		padding: 5px;
	}
	.viewListByCount .filterContent label{
		display: none;
	}
	.curriculamFilter .filterContent {
		width: 45%;
		margin-right: 2%;
		float: left;
	}
	.curriculamFilter .filterContent:first-child {
		float: right;
	}
	.viewListByCount .curricFilterSelect {
		width: 100%;
	}
	.viewListByCount .curricFilterSelect.currSearchKey{
		margin-left: 0;
	}
	.viewListByCount table.curriculumTable tbody td.curriculumButtonPanel {
		padding: 0
	}
}
/* View curriculum by country Screen Mobile CSS Ends */

/* Upload Curriculum page Mobile CSS Starts */
@media screen and (max-width: 780px) and (min-width: 320px) {
	.curriculumTab.uploadCurriculum {
		margin-top: 75px;
	}
	.uploadSelectBtn.cmButton.cmButton-green {
		min-width: unset;
		width: 100%;
	}
	.curriculumEmptyListPanel{
		margin-left: 15px;
		margin-right: 15px;
	}
	.uploadCurriculum .upperPartForMble.row {
		width: 100%;
		margin-left: 0;
	}
	.uploadCurriculum .upperPartForMble .span2{
		width: 100%;
		margin-left: 0;
	}
	.uploadCurriculum select.cmDropDown.currIngestDropDown {
		width: 100%;
	}
	.uploadCurriculum .sectionTwo.row {
		margin-left: 0;
	}
	.uploadCurriculum .sectionTwo .span2{
		width: 100%;
		margin-left: 0;
	}
	.uploadCurriculum .sectionTwo .span4{
		width: 100%;
		margin-left: 0;
	}
	.uploadCurriculum .sectionTwo .span2 input, .uploadCurriculum .sectionTwo .span4.sectionTwoSp4 input, .sectionThree .span3 input {
		width:95%;
	}
}
/* Upload Curriculum page Mobile CSS Ends */

/* Update Curriculum page Mobile CSS Starts */
@media screen and (max-width: 780px) and (min-width: 320px) {
	.curriculumTab.updateCurrTab {
		margin-top: 75px;
	}
	.updateCurrTab.cmButton.cmButton-green {
		min-width: unset;
		width: 100%;
	}
	.updateCurrTab select.cmUpdateDraftDropDown {
		width: 100%;
	}
	.updateCurrTab .row.updateCurrRow {
		margin-left: 0;
		width: 100%;
	}
	.updateCurrTab .updateCurrWid300.span2 {
		width: 100%;
		margin-left: 0;
	}
	.updateCurrTab .span2Update input.appendTitleInput {
		margin-top: 0;
		vertical-align: top;
		float: left;
		margin-right: 6px;
	}
	.updateCurrTab .span2Update .appendTitleSpan {
		display: block;
	}
	.updateCurrTab .span2Update {
		padding-left: 0px;
	    padding-top: 0px;
	}
	.uploaderErrorPanel {
		width: 100%;
	}
}
/* Update Curriculum page Mobile CSS Ends */

/* Manage Authority Mobile CSS Starts */
@media screen and (max-width: 780px) and (min-width: 320px) {
	.authoritySection .curriculumTab {
		margin-top: 85px;
	}
	div#CreateProgramPopup {
		width: 100% !important;
		margin-left: 0 !important;
		left: -1px;
	}
	#CreateProgramPopup button.close {
		margin-right: 0 !important;
		margin-top: 0 !important;
		position: absolute;
		right: 0;
		z-index: 9;
	}
	#CreateProgramPopup .addorEditAuthSection, #CreateProgramPopup .addOrEditCurrSetSection {
		margin-left: 0 !important;
	}
	#CreateProgramPopup .modal-body {
		padding: 5px;
	}
	#CreateProgramPopup .addOrEditCurrCls1 input.form-control, #CreateProgramPopup .addOrEditAuthCls1 input.form-control {
		width: 95% !important
	}
}
/* Manage Authority Mobile CSS Ends */

/* Upload product Mobile CSS Starts */
@media screen and (max-width: 780px) and (min-width: 320px) {
	.uploadProducTab.curriculumTab {
		margin-top: 75px;
	}
	.uploadProducTab.cmButton.cmButton-green {
		min-width: unset;
		width: 100%;
	}
	.uploadProducTab select.cmDropDown.currIngestDropDown {
		width: 100%;
	}
	.uploadProducTab .row {
		margin-left: 0;
		width: 100%;
	}
	.uploadProducTab .span2 {
		width: 100%;
		margin-left: 0;
	}
	.uploadProducTab .span2 {
		width: 100%;
		margin-left: 0;
	}
	.uploadProducTab .span3.product-download-container, .uploadProducTab .span3.product-lock-container{
		margin-bottom: 10px;
	}
	.uploadProducTab .ignoreIntAlignUploadProd input{
		float: left;
	}
	.uploadProducTab .ignoreIntAlignUploadProd label{
		width: 90%;
	}
}
/* Upload product Mobile CSS ends */

/* Manage Product Mobile CSS Starts */
@media screen and (max-width: 780px) and (min-width: 320px) {
	.manageProductTab.curriculumTab {
		margin-top: 85px;
	}
	#CreateProgramPopup input.form-control{
		width: 95% !important
	}
	#CreateProgramPopup .addOrEditProgramSection .marginTop30, #CreateProgramPopup .addOrEditCourseSection .marginTop30 {
		margin-top: 20px;
	}
	#CreateProgramPopup .addOrEditProgramSection, #CreateProgramPopup .addOrEditCourseSection {
		margin-left: 5px !important;
	}
}
/* Manage Product Mobile CSS Ends */

/* Upload Intermediary Mobile CSS Starts */
@media screen and (max-width: 780px) and (min-width: 320px) {
	.uploadIntermediarySection.curriculumTab {
		margin-top: 75px;
	}
	.uploadIntermediarySection.curriculumTab .curriculumEmptyListPanel .span2{
		width: 100% !important;
	}
	.uploadIntermediarySection.curriculumTab .curriculumEmptyListPanel .currIngestDropDown{
		width: 100%;
	}
	.uploadIntermediarySection.curriculumTab .intermediary-export-link {
		padding-left: 0;
		margin: 0px 0px 10px 0px;
	}
}
/* Upload Intermediary Mobile CSS Ends */

/* Substitution Sets Mobile CSS Starts */
@media screen and (max-width: 780px) and (min-width: 320px) {
	.substitutionSetSection {
	    margin-top: 45px;
	}
	.substitutionSetSection .subsMenu {
		width: 100%;
		overflow-x: auto;
	}
	.substitutionSetSection .subsMenu::-webkit-scrollbar {
		display: none;
	}
	.substitutionSetSection .subsMenu  ul{
		width: max-content;
	}
	.substitutionSetSection .subsContent {
		padding: 5px;
	}
	.substitutionSetSection .uploader {
		text-align: center;
	}
	.substitutionSetSection .uploadSelectBtn.cmButton.cmButton-green {
		width: 75%;
	}
	.substitutionSetSection .substitution-export-link {
		margin-top: 10px;
		margin-bottom: 10px;
		padding-left: 5px;
	}
	.substitutionSetSection .selectSubsSetLab, .substitutionSetSection .selectSubsSetLab .cmUpdateDraftDropDown.currUpdateDraftDropDown {
		width: 100%;
	}
	.createSub.updateSub .createSubsLabel,.createSub.updateSub .updateSubsLabel {
		margin-left: 0px;
	}
	.createSub.updateSub .createSubsButton.correlation-button {
		margin: 0px 5px 10px;
	}
	.updateSubsRowSpan12.span12 {
		width: 100%;
	}
	.updateSubsRowSpan12.span12 .span1{
		width: auto;
		float: left;
		margin-left: 2%;
	}
	.createSub.updateSub .span4 input.form-control{
		text-indent: 8px;
		padding: 5px 0px;
	}
	.editSubsTopicTree.row-fluid {
		margin-top: 0px;
	}
	.subsTemplateRight .discoverTermsIcon.discoverTermsIconExpand, .subsTemplateRight .discoverTermsIcon.discoverTermsIconCollapse {
		display: inline-block;
	}
	.topicTreeTable.subsTemplateLeft {
		display: none;
	}
	.correlation-button.correlation-report-lo-button.viewEditSubsMbleFull{
		margin-top: 23px;
		background-color: transparent !important;
		color: #79bb73 !important;
		border: 1px solid #79bb73 !important;
	}
	.forMbleScrollEditSub.activate .topicTreeTable.subsTemplateLeft {
		display: table;
	}
	.forMbleScrollEditSub.activate .subsTemplateRight .discoverTermsIcon.discoverTermsIconExpand, .forMbleScrollEditSub.activate .subsTemplateRight .discoverTermsIcon.discoverTermsIconCollapse {
		display: none;
	}
	.row-fluid.editSubsTopicTree {
		overflow-x: auto;
	}
	.forMbleScrollEditSub.activate {
		width: 860px;
	    margin-top: 25px;
	}
	.forMbleScrollEditSub.activate .span6 {
	    width: 50%;
	    float: left;
	}
	#editSubsTopicPopup {
		width: 100% !important;
	    margin-left: 0px !important;
	    left: -1px;
	}
	#editSubsTopicPopup button.close {
	    position: absolute;
	    right: -2px;
	    z-index: 9;
	}
	#editSubsTopicPopup .modal-body {
	    padding: 5px;
	}
	#editSubsTopicPopup .editSubsPopup {
		margin-left: 5px !important;
	}
	.currEditHeader{
		top: 60px;
	}
	.clearfix.currEditTopButton {
		margin-right: 0px !important;
	}
	.currEditTopButton input.cmButton.floatRight{
		float: left;
		width: 30% !important;
		margin-left: 2%;
		font-size: 75%;
		padding: 0px;
		margin-bottom: 10px
	}
	.currEditHeader .discoverTermsText {
		margin-left: 0px;
	}
	.currEditHeader .borderMarginParent {
		width: 95%;
	}
	.currEditHeader .borderMarginChild .discoverTermsText {
		width: 90%;
		margin-left: 5%;
	}
	.currEditHeader .smallEditIcon {
		margin-left: 10px;
		vertical-align: sub;
	}
	.currEditHeader .customtitle {
		margin-right: 5px;
	}
	.currEditHeader input.cmButton.cmButtonFull-green.cmButton-standard {
		margin: 10px;
	}
	#CreateTagsPopup, #CreateTopicPopup{
		width: 100% !important;
		top: 0px !important;
		margin-left: -11px !important;
	}
	#CreateTagsPopup .EditCurriculumPopup {
		margin-left: 0px !important;
	}
	#CreateTagsPopup .modal-body, #CreateTopicPopup .modal-body{
		padding: 10px;
	}
	#CreateTagsPopup select, #CreateTopicPopup select {
		width: 100% !important;
	}
	#CreateTagsPopup button.close, #CreateTopicPopup button.close {
	    position: absolute;
	    right: 0;
	    z-index: 9;
	}
	#CreateTagsPopup #localGradeName {
		width: 96% !important;
	}
	.currEditHeader .marginLeft10.paddingTop20 {
		margin-left: 0;
	}
	#CreateTopicPopup .editCurrTopicEditContr {
		margin-left: 0px !important;
	}
	.modal-dialog.addTopicPopup .modal-content {
		width: auto;
		right: auto;
	}
}
/* Substitution Sets Mobile CSS Ends */	

/* Intermediary Align Mobile CSS Starts */	
@media screen and (max-width: 780px) and (min-width: 320px) {
	.intermediary-section{
		width: 100%;
	    padding: 0;
		overflow: hidden;
		margin-top: 45px;
	}
	.intermediary-section .intrm-tab-content {
		width: 100%;
		left: 0;
	    top: 105px;
	}
	.intermediary-section ul.tabs li {
	    width: 48% !important;
	    padding: 15px 1%;
	    text-align: center;
	}
	.intermediary-list-panel {
		width: 100%;
	}
	.intermediary-dropdown {
		width: 100%;
	}
	#standard-bottom-link .span4, #content-bottom-link .span4 {
	    min-height: 0px;
	}
	#standard-bottom-link, #content-bottom-link{
	    padding: 0px;
	}
	#standard-bottom-link .span4.intermediary-create-alignment-link, #content-bottom-link .span4.intermediary-create-alignment-link  {
	    padding: 20px;
	}
	
	.ui-layout-column .ui-splitbar, .span2.intermediary-back{
		display: none !important;
	}
	.layout-container-custom.ui-layout-column {
		margin: 0 !important;
	}
	.ng-isolate-scope.stretch.ui-layout-container{
		top: 15px;
		left: 2% !important;
	}
	.standard-mapping-btn-container {
		padding-left: 5px;
	}
	.intermediary-section, .intermediary-mapping-section {
	    margin-top: 50px;
	}
	#learningObjectiveSelectionTree {
		width: 100%;
		overflow-x: auto;
	}
	#learningObjectiveSelectionTree ul.jstree-container-ul {
		width: 980px;
	}
	#learningObjectiveSelectionTree span.span11_standard {
	    width: 90% !important;
	    float: left !important;
	}
	#learningObjectiveSelectionTree span.span1 {
	    width: 5% !important;
	    float: left !important;
	}
	#learningObjectiveSelectionTree span.span10.textContainer {
	    width: 85% !important;
	    float: left;
	}
	.standard-mapping-btn-container {
		width: 98%;
		overflow-x: auto;
	}
	.standard-mapping-btn-container::-webkit-scrollbar {
		display: none;
	}
	.stdMappContFrMble {
		display: block;
		width: max-content;
	}
	.intermediary-filter-container {
		width: 25%;
	}
	.discipline-search-container {
		width: 35%;
	}
	.discipline-search-container select.intermediary-dropdown-subject {
		width: 90%;
	}
	.intermediary-search-container {
		width: 41%;
	}
	.intermediary-search-container #searchButtonId {
		width: 65%;
	}
	.mapping-title-label-container {
		padding-top: 5px;
	}
	.intermediary-statement.row-fluid span.span2.intermediary-type-container {
		width: 15%;
		float: left;
	}
	.intermediary-statement.row-fluid span.span9.search-intermediary {
		width: 75%;
		float: left;
	}
	.intermediary-statement.row-fluid span.span1.intermediary-add-icon {
		width: 10%;
		float: left;
	}
	.std-text-container, .content-text-container {
		width: 96% !important;
	}
	.intermediary-standard-tree-container {
	    padding-left: 1px;
	}
	i.jstree-icon.jstree-ocl {
	    width: 15px !important;
	    height: 15px !important;
	    padding: 5px;
	}
	button.hideFromDesk.intermediary-cm.intermediary-btn.resetLeftForMble {
	    position: absolute;
	    bottom: 30px;
	    right: 40%;
	    font-size: 16px !important;
	    padding: 5px 15px;
	}
	.product-iframe-container {
	    width: 98%;
	    padding: 2px;
	}
	.cart-intermediaries-list.row-fluid .span2 {
	    width: 24%;
	    float: left;
	    display: inline-block;
	}
	.cart-intermediaries-list.row-fluid span.span7.knowledge-cart-text-container {
	    width: 60%;
	    display: inline-block;
	    margin: 1%;
	}
	.cart-intermediaries-list.row-fluid .span3 {
	    width: 13%;
	    float: right;
	}
	#learningObjectiveSelectionTree span.span1 .marleft-25 {
		margin-left: 0px;
	}
	.jstree-default>.jstree-no-dots .jstree-closed>.jstree-ocl {
	    background-position: -1px -2px;
	}
	.jstree-default>.jstree-no-dots .jstree-open>.jstree-ocl {
	    background-position: -34px -1px;
	}
	.span1.intermediary-add-icon img {
	    float: right;
	    margin-right: 5px;
	}
}
/* Intermediary Align Mobile CSS Ends */	

/* Correlation report Mobile CSS Starts */	
.correlationTabForMble, .dummyBlackForMble {
	display: none;
}
.postionRoundIcon.popupCloseCorr.smallDeleteIcon.marginTop5px {
	display: none;
}
.correlationTabContainerId.leftCorrelation {
	margin-left: 0px;
}
@media screen and (max-width: 780px) and (min-width: 320px) {
	.mapping-alignment-label {
	    font-size: 24px;
	    font-weight: bold;
	}
	.row-fluid.correlation-tabs-container {
	    display: none;
	}
	.span6.alignmentContainerId.centerCorrelation {
		display: none !important;
	}
	.correlationTabForMble {
		display: block;
		padding: 5px;
	}
	.correlationTabForMble .span6.alignmentContainerId {
	    width: 100% !important;
	    float: left;
	    top: 0px;
	}
	.correlationTabForMble .alignmentcheckboxdiv label.chkboxlbl {
	    font-size: 80%;
	}
	.correlationTabForMble .leftSideTabSelection {
	    width: 100%;
	    float: left;
	}
	.correlationTabForMble .rideSideTabSelection {
	    width: 100%;
	    float: left;
	}
	.correlationTabForMble .leftSideTabSelection .span11 {
	    width: 90%;
	    float: left;
	}
	.correlationTabForMble .leftSideTabSelection .span1 {
	    width: 10%;
	    float: right;
	}
	span.postionRoundIcon.smallDeleteIcon.marginTop5px {
	    float: right;
	    margin-right: 6%;
	}
	
	.row-fluid.correlation-tabs-container.forLeftCorrData {
	    display: block;
        position: fixed;
	    background: #fff;
	    width: 98%;
	    margin-left: 1%;
	    top: 40px;
	    z-index: 9;
	}
	.row-fluid.correlation-tabs-container.forLeftCorrData .rightCorrelation{
	    display: none;
	}
	.row-fluid.correlation-tabs-container.forRightCorrData {
	    display: block;
	    position: fixed;
	    background: #fff;
	    width: 98%;
	    margin-left: 1%;
	    top: 40px;
	    z-index: 9;
	}
	.dummyBlackForMble.openRightLeftCorrData{
		display: block;
	    width: 100%;
	    min-height: 700px;
	    position: fixed;
	    top: 0;
	    background: black;
	    opacity: 0.5;
	    z-index: 1;
	}
	.row-fluid.correlation-tabs-container.forRightCorrData .leftCorrelation{
	    display: none;
	}
	.row-fluid.correlation-tabs-container #tabsContainerId {
	    width: 100% !important;
        margin: 0 !important;
	}
	.intermediary-section .row-fluid.correlation-tabs-container ul.tabs li {
	    width: 30% !important;
	    padding: 10px 1% !important;
	    text-align: center;
	    overflow: hidden;
	}
	.row-fluid.correlation-tabs-container .correlation-row-gab .span6.text-right {
		width: 75%;
	}
	.row-fluid.correlation-row-gab .span11 {
	    width: 90%;
	    float: left;
	}
	.row-fluid.correlation-row-gab .span1 {
	    width: 10%;
	    float: right;
	}
	.postionRoundIcon.popupCloseCorr.smallDeleteIcon.marginTop5px {
		display: block;
		position: absolute;
	    top: -4px;
	    right: -23px;
	}
	.correlation-report-tab-content.current {
	    margin-top: -5px;
	}
	td.width75P.defaultTdFrMble {
	    padding-bottom: 5px;
	    color: #999;
	}
	.row-fluid.correlation-row-gab.mbleClassAddRightCorr {
	    max-height: 180px;
	    overflow-y: auto;
	}
	.correlation-save-container.posRelative {
	    margin: 10px 0px;
	}
	.correlation-report-options-container {
		margin: 0;
	    padding: 10px 0px;
	}
	.correlation-report-options {
	    display: block;
    	width: 100%;
	}
	.correlation-report-input.correlation-checkbox.ng-scope {
	    display: inline-block;
	}
	.correlation-report-list-container {
	    padding: 10px 0px;
	}
	.correlation-report-btn-container {
		margin: 10px 0px;
	    text-align: center;
	}
	.correlation-filter-container {
	    display: block;
	    width: 100%;
	}
	.correlation-filter-container select#filter {
	    width: 75%;
	}
	.correlation-filter-container label.correlation-view-label {
	    vertical-align: text-bottom;
	}
	div#correlationsDatatablesId_filter {
		width: 60%;
   		display: inline-block;
		margin-top: 0px;
		vertical-align: top;
	}
	div#correlationsDatatablesId_length {
		display: inline-block;
   		width: 40%;
	}
	#correlationsDatatablesDivId input {
	    margin-bottom: 0 !important;
	    width: 75%;
	    float: left;
	}
	img.correlation-search-icon {
	    padding: 5px;
	    margin-top: 0;
	}
	.corrTableWrapper {
	    width: 100%;
	    overflow-x: auto;
	}
}
/* Correlation report Mobile CSS Ends */

@media screen and (max-width: 780px) and (min-width: 320px) {
	.modal-dialog.addAuthorityPopup .modal-content {
		width: 100%;
		margin-left: 0;
	}
	.addorEditAuthSection {
		margin-left: 0px !important;
	}
	.addAuthorityInputs {
		width: 100% !important;
	}

	.modal-dialog.addLocalGradePopup select.font_12_666666 {
		width: 100%;
	}
	.modal-dialog.addLocalGradePopup {
		width: 96%;
	}
}